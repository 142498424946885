import { Injectable } from '@angular/core';

@Injectable()
export class AuthForUserGroupService {
    public UserGroupInfor: any = {
        Eid: "",
        OPEmail: "",
        Group: []
      };
    constructor() { }
    
    setUserInforFn(userGroupInfor){
        this.UserGroupInfor = userGroupInfor;
        console.log("UserGroupInfor",this.UserGroupInfor);
    }

    getUserInforFn(){
        return this.UserGroupInfor;
    }
}