import { Injectable }       from '@angular/core';
import { Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot }                           from '@angular/router';
import { Factory } from 'app/app.factory';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { AuthForUserGroupService } from 'app/common/services/UserAuth/AuthForUserGroupService';
import { UserProfileService } from 'app/common/eso/userprofile.service';
import { ReturnMessage } from 'app/common/enum/type.enum';
import { SessionService }  from 'app/common/services/session/session.service';

@Injectable()
export class AuthGuard  {
	public service: any;

  constructor(
    private router: Router,
    private factory: Factory,
    private auth: AuthService,
    private authForUserGroup: AuthForUserGroupService,
    private UserProfile: UserProfileService,
    private session: SessionService,
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean>  {
    this.service = this.factory.getService();
    var userInfor =  this.auth.getUserInforFn();
    this.session.setTimeFn(new Date());
    if(userInfor.EID!=""){
      return true;
    } else{
      return new Promise((resolve, reject) => {
        var eid = this.UserProfile.EnterpriseId;
        var data = {Eid: eid}
        console.log("trigger getUserByEid");
        this.service.getUserByEid(data).then(res=>{
          if(res.Status == ReturnMessage.Success){
            var userInfor = {
              EID: res.Data.EID,
              LogInAs: res.Data.LogInAs,
              Uri: res.Data.Uri,
              Email: res.Data.Email,
              Group: res.Data.Group,
              KeyCategory: res.Data.KeyCategory,
              Gu: res.Data.Gu,
              OPEmail: res.Data.OPEmail,
              Salutation: res.Data.Salutation,
              UserCountry: res.Data.UserCountry,
              GuLead: res.Data.GuLead
            }
            this.auth.setUserInforFn(userInfor);

            // this.service.getUserGroupOPEmailByEid(data).then(res => {
            //   if (res.Status == ReturnMessage.Success) {
            //     var userGroup = {
            //       Eid: res.Data.Eid,
            //       OPEmail: res.Data.OPEmail,
            //       Group: res.Data.Group
            //     };
                console.log("Done getUserGroupOPEmailByEid");
                this.authForUserGroup.setUserInforFn(userInfor);
                if (userInfor.Group.length > 0) {
                  console.log("return Navgigate NoAccess For Groups:" + userInfor.Group.length as string);
                  resolve(true);
                } else {
                  console.log("Done Navgigate NoAccess");
                  this.router.navigate(['/noaccess'], { queryParams: { email: userInfor.OPEmail } });
                }
                resolve(true);
              // } else {
              //   console.log("ERROR Navgigate NoAccess ", res.Status);
              //   resolve(false);
              //   // this.router.navigate(['/noaccess'],{ queryParams: { email: this.userGroupInfor.OPEmail}});
              // }
            // }, err => {
            //   console.log("ERROR Navgigate NoAccess", err);
            //   resolve(false);
            //   // this.router.navigate(['/noaccess'],{ queryParams: { email: this.userGroupInfor.OPEmail}});
            // });
          }else{
            console.log("get user error", res.Status);
            resolve(false);
          }
        },err =>{
          console.log("get user error", err);
          reject(false);
        })
      });
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    return false;
  }
}
