import { Injectable } from "@angular/core";
// import { AdalService } from "./adal.service";
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
@Injectable()
export class UserProfileService {
  private _access;
  constructor(private rebarAuthService: RebarAuthService) {
    // eslint-disable-next-line no-debugger
    // debugger
    // console.log(this.adal);
    console.log("rebarAuthService:", this.rebarAuthService);
  }
  // get FullProfile() {
  //   return this.adal.userInfo;
  // }
  get FullProfile() {
    console.log("FullProfile:", this.rebarAuthService.getAllAccounts)
    return this.rebarAuthService.getAllAccounts;
  }

  // get EnterpriseId() {
  //   let s = "";
  //   if (this.adal.userInfo) {
  //     s = this.adal.userInfo.profile.unique_name;
  //     const i = s.indexOf("@");
  //     if (i > -1) {
  //       s = s.substring(0, i);
  //     }
  //     return s;
  //   }

  //   return s;
  // }
  get EnterpriseId() {
    let s = "";
    if (this.rebarAuthService.getUser()) {
      console.log("EnterpriseId:", this.rebarAuthService.getUser());
      s = this.rebarAuthService.getUser();
      const i = s.indexOf("@");
      if (i > -1) {
        s = s.substring(0, i);
      }
      return s;
    }

    return s;
  }

  // get PeopleyKeyNbr() {
  //   return this.adal.userInfo ? this.adal.userInfo.profile.unique_name : 0;
  // }
  get PeopleyKeyNbr() {
    return this.rebarAuthService.getUser() ? this.rebarAuthService.getUser() : 0;
  }

  get DisplayName() {
    // return this.adal.userInfo
    //   ? this.adal.userInfo.profile.given_name
    //     ? this.adal.userInfo.profile.given_name +
    //       " " +
    //       this.adal.userInfo.profile.sn
    //     : this.adal.userInfo.profile.unique_name
    //   : "";
    return this.rebarAuthService.getUserProfile()['username'];
  }

  get UserToken() {
    // return this.adal.Token;
    return "";
  }
  get UserAccess() {
    return this._access;
  }

  set UserAccess(access) {
    this._access = access;
  }
}
